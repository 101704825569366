/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-11 17:21:33
 * @Module: 转发详情
 */
 <template>
  <div class="detail"
       v-if="detailData">
    <detail-userinfo :data="detailData" />
    <div class="transpondDetail-text">
      {{detailData.moment_content.shareable_description}}
    </div>
    <transpondModule :data="detailData" />
    <tip-off :data="detailData"
             @onLike="onLike"
             @onTipOff="onTipOff" />
    <comment :data="detailData" />
    <recommend :data="detailData" />
  </div>
</template>
 <script>
import transpondModule from "@/views/homepage/module/transpondModule"
import detailMixins from "./detailMixins"
export default {
  components: {
    transpondModule
  },
  mixins: [detailMixins],
  data () {
    return {};
  },
  mounted () { },
  methods: {},
  computed: {
  }
};
 </script>
 <style lang='scss' scoped>
@import url("./style.scss");
.transpondDetail-text {
  font-size: 16px;
  color: #333333;
}
</style>